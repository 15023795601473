<template>
	<InterviewArrangements></InterviewArrangements>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import InterviewArrangements from "@/components/shared/tools/interview/Arrangements";

export default {
	name: "InterviewsPage",

	components: {
		InterviewArrangements,
	},

	computed: {
		...mapFields("interview", {
			current_tab: "current_tab",
		}),
	},

	beforeDestroy() {
		this.current_tab = "plan-interview";
	},
};
</script>
